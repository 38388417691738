import React, { useContext } from 'react'
import { TranslateContext } from 'client/common/translator'
import {
  getProductListTranslations,
  getPromotionsTextsTranslations
} from 'client/fragments/listerFragment/selectors/translations'
import { Card } from './Card/Card'
import PropTypes from 'prop-types'
import { Box, Flex, MOBILE, propConverter } from 'chemistry'

const CardWrapper = ({ cardClassName, key, onClick, children, sx, ...props }) => {
  // legacy block -- remove it when removing cardClassName from getProductCards
  if (cardClassName) {
    return (
      <div className={cardClassName} key={key} onClick={onClick}>
        {children}
      </div>
    )
  }

  return (
    <Box
      sx={{
        [MOBILE]: {
          width: '50%'
        },
        '@media screen and (max-width: 540px)': {
          width: '100%'
        },
        ...sx
      }}
      key={key}
      onClick={onClick}
      {...props}
    >
      {children}
    </Box>
  )
}

CardWrapper.propTypes = {
  cardClassName: PropTypes.string,
  key: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.object,
  sx: PropTypes.object
}

// transform into React pure component in the future as it violates React's naming convention
export const getProductCards = ({
  collection,
  t,
  lang,
  format,
  sizes,
  cardClassName, // legacy -- remove in the future when no mxd classes will be used
  cardWrapperProps,
  onProductClick,
  checkedProducts,
  onProductCheck,
  target,
  showBVRatingOnListerCards
}) =>
  collection.map((props, index) => {
    const productListTranslations = getProductListTranslations(t)
    if (props.unitName) {
      const per = lang === 'fr' ? 'par' : 'per'
      const unitTranslated = t(`pim.units.${props.unitName}`)
      productListTranslations.units = `${per} ${unitTranslated}`
    }

    const cardProps = {
      ...props,
      poster: {
        thumbUrl: props.image,
        alt: props.title,
        title: props.title
      },
      format,
      lang,
      isDetail: false,
      t: productListTranslations,
      texts: getPromotionsTextsTranslations(t),
      translate: t,
      target,
      ...(onProductCheck && {
        isChecked: checkedProducts.includes(props.id),
        onProductCheck
      })
    }
    const fullPriceProps = propConverter(cardProps)

    const imgProps = { loading: 'lazy' }
    return (
      <CardWrapper
        cardClassName={cardClassName}
        key={`product-${props.ean}`}
        onClick={ev => onProductClick(props, index + 1, ev)}
        {...cardWrapperProps}
      >
        <Card
          {...cardProps}
          sizes={sizes}
          fullPriceProps={fullPriceProps}
          showBVRatingOnListerCards={showBVRatingOnListerCards}
          imgProps={imgProps}
        />
      </CardWrapper>
    )
  })

const Products = ({ collection, listProps: { sx: listPropsSx, ...listProps } = {}, ...props }) => {
  const t = useContext(TranslateContext)
  if (collection?.length <= 0) return null

  const elements = getProductCards({
    t,
    collection,
    ...props
  })

  return (
    <Flex
      sx={{
        overflow: 'auto',
        [MOBILE]: {
          flexWrap: 'wrap'
        },
        ...listPropsSx
      }}
      {...listProps}
    >
      {elements}
    </Flex>
  )
}

Products.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.oneOf(['nl', 'fr']),
  format: PropTypes.oneOf(['praxis', 'brico']),
  onProductClick: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.oneOf(['sm', 'md', 'lg'])),
  checkedProducts: PropTypes.arrayOf(PropTypes.string),
  onProductCheck: PropTypes.func,
  target: PropTypes.string,
  listProps: PropTypes.object,
  cardWrapperProps: PropTypes.object
}

export default Products
